import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Alert from 'react-bootstrap/Alert'
import { CodeViewer } from '@stoplight/mosaic-code-viewer'

import '../styles/send_event_form.scss';

export default class V2EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routingKey: "",
      eventAction: "",
      dedupKey: "",
      images: "",
      links: "",
      linkText: "",
      payloadSummary: "",
      payloadSource: "",
      payloadSeverity: "",
      payloadTimestamp: "",
      payloadComponent: "",
      payloadGroup: "",
      payloadClass: "",
      payloadCustomDetails: "",

      postId: null,
      responsebody: null,
      statusbar: "",
      statuscolor: "secondary",
      err: null,
    };

    this.fieldTypes = {
      ARRAY_OF_OBJECTS: "Array of Objects",
      OBJECT: "Object",
      STRING: "String",
      TIMESTAMP: "timestamp"
    };

    this.popovers = {
      eventAction: this.getPopover(this.fieldTypes.STRING,
        "The type of event. Can be trigger, acknowledge or resolve."),
      routingKey: this.getPopover(this.fieldTypes.STRING,
        "This is the 32 character Integration Key for an integration on a service or on a global ruleset."),
      dedupKey: this.getPopover(this.fieldTypes.STRING,
        "Deduplication key for correlating triggers and resolves. The maximum permitted length of this property is 255 characters."),
      payloadSummary: this.getPopover(this.fieldTypes.STRING,
        "A brief text summary of the event, used to generate the summaries/titles of any associated alerts. The maximum permitted length of this property is 1024 characters."),
      payloadSource: this.getPopover(this.fieldTypes.STRING,
        "The unique location of the affected system, preferably a hostname or FQDN."),
      payloadSeverity: this.getPopover(this.fieldTypes.STRING,
        "The perceived severity of the status the event is describing with respect to the affected system. This can be critical, error, warning or info."),
      payloadTimestamp: this.getPopover(this.fieldTypes.TIMESTAMP,
        "The time at which the emitting tool detected or generated the event."),
      payloadComponent: this.getPopover(this.fieldTypes.STRING,
        "Component of the source machine that is responsible for the event, for example mysql or eth0"),
      payloadGroup: this.getPopover(this.fieldTypes.STRING,
        "Logical grouping of components of a service, for example app-stack"),
      payloadClass: this.getPopover(this.fieldTypes.STRING,
        "The class/type of the event, for example ping failure or cpu load"),
      payloadCustomDetails: this.getPopover(this.fieldTypes.OBJECT,
        "Additional details about the event and affected system"),
      images: this.getPopover(this.fieldTypes.ARRAY_OF_OBJECTS,
        "List of images to include."),
      links: this.getPopover(this.fieldTypes.STRING,
        "URL of the link to be attached."),
      linkText: this.getPopover(this.fieldTypes.STRING,
        "Plain text that describes the purpose of the link, and can be used as the link's text."),
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  sumbitform() { // submitForm
    let requestBody = {
      "payload":
      {
        "summary": this.state.payloadSummary,
        "severity": this.state.payloadSeverity,
        "source": this.state.payloadSource,
        "component": this.state.payloadComponent,
        "group": this.state.payloadGroup,
        "class": this.state.payloadClass,
      },
      "routing_key": this.state.routingKey,
      "event_action": this.state.eventAction,
      "dedup_key": this.state.dedupKey,
    }

    if (this.state.links) {
      requestBody.links = [{
        "href": this.state.links,
        "text": this.state.linkText,
      }]
    }

    if (this.state.payloadCustomDetails) {
      try {
        requestBody.payload.custom_details = JSON.parse(this.state.payloadCustomDetails)
      } catch (e) {
        // invalid JSON, accept it as a string
        requestBody.payload.custom_details = this.state.payloadCustomDetails
      }
    }

    if (this.state.payloadTimestamp) {
      requestBody.payload.timestamp = (this.state.payloadTimestamp)
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    };

    fetch('https://events.pagerduty.com/v2/enqueue', requestOptions)
      .then(response => response.json())
      .then(data => { this.setState({ responsebody: data, }) })
      .then(error => { this.setState({ err: error }) });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.sumbitform(event);
  }

  colorChange(color) {
    if (color == "success") {
      return "success"
    }
    return "danger"
  }

  statusUpdate(statusNum) {
    if (statusNum == "success") {
      return "202 Accepted"
    }
    return "400 Bad Request"
  }
  getPopover(type, description) {
    return (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Type: {type}</Popover.Header>
        <Popover.Body>
          {description}
        </Popover.Body>
      </Popover>
    )
  }

  render() {
    return (

      <div className="px-2 pt-10  send-event-form">
        <Form onSubmit={this.handleSubmit}>
          <div className="flex flex-wrap -mx-2">
            <div className="sm:w-1/2 md:w-1/3 px-2">
              <div className="bg-gray-400 h-12"></div>
              <Form.Group controlId="routingKey">
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.routingKey}>
                  <Form.Label>routing_key</Form.Label>
                </OverlayTrigger>
                <span className="text-red">*</span>
                <Form.Control
                  required
                  type="text"
                  name="routingKey"
                  value={this.state.routingKey}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="eventAction">
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.eventAction}>
                  <Form.Label>event_action</Form.Label>
                </OverlayTrigger>
                <span className="text-red">*</span>
                <Form.Control required as="select" name="eventAction" value={this.state.eventAction} onChange={this.handleInputChange} >
                  <option> </option>
                  <option value="trigger">trigger</option>
                  <option value="acknowledge">acknowledge</option>
                  <option value="resolve">resolve</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="dedupKey">
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.dedupKey}>
                  <Form.Label>dedup_key</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="dedupKey"
                  value={this.state.dedupKey}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="payload.summary">
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadSummary}>
                  <Form.Label>payload.summary</Form.Label>
                </OverlayTrigger>
                <span className="text-red">*</span>
                <Form.Control
                  required
                  type="text"
                  name="payloadSummary"
                  value={this.state.payloadSummary}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadSource}>
                  <Form.Label>payload.source</Form.Label>
                </OverlayTrigger>
                <span className="text-red">*</span>
                <Form.Control
                  required
                  type="text"
                  name="payloadSource"
                  value={this.state.payloadSource}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </div>
            <div className="sm:w-1/2 md:w-1/3 px-2">
              <div className="bg-gray-500 h-12"></div>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadSeverity}>
                  <Form.Label>payload.severity</Form.Label>
                </OverlayTrigger>
                <span className="text-red">*</span>
                <Form.Control
                  required
                  as="select"
                  name="payloadSeverity"
                  value={this.state.payloadSeverity}
                  onChange={this.handleInputChange}
                >
                  <option> </option>
                  <option value="critical">critical</option>
                  <option value="error">error</option>
                  <option value="warning">warning</option>
                  <option value="info">info</option>
                </Form.Control>

              </Form.Group>

              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadTimestamp}>
                  <Form.Label>payload.timestamp</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="payloadTimestamp"
                  value={this.state.payloadTimestamp}
                  onChange={this.handleInputChange}
                />

              </Form.Group>

              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadComponent}>
                  <Form.Label>payload.component  </Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="payloadComponent"
                  value={this.state.payloadComponent}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadGroup}>
                  <Form.Label>payload.group </Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="payloadGroup"
                  value={this.state.payloadGroup}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadClass}>
                  <Form.Label>payload.class</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="payloadClass"
                  value={this.state.payloadClass}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </div>
            <div className="sm:w-1/2 md:w-1/3 px-2">

              <div className="bg-gray-400 h-12"></div>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.images}>
                  <Form.Label>images </Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="images"
                  value={this.state.images}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.links}>
                  <Form.Label>link.href </Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  name="links"
                  placeholder="https://example.com"
                  value={this.state.links}
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.linkText}>
                  <Form.Label>link.text</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  placeholder="Example"
                  name="linkText"
                  value={this.state.linkText}
                  onChange={this.handleInputChange}
                />
              </Form.Group>
              <div className="bg-gray-400 h-12"></div>
              <Form.Group>
                <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={this.popovers.payloadCustomDetails}>
                  <Form.Label>payload.custom_details</Form.Label>
                </OverlayTrigger>
                <Form.Control
                  as="textarea"
                  type="text"
                  name="payloadCustomDetails"
                  placeholder={" { \"key\" : \"value\"}"}
                  value={this.state.payloadCustomDetails}

                  onChange={this.handleInputChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit">Submit</Button>
            </div>
          </div>

          <div id="status bar">
            {this.state.responsebody &&
              <Alert variant={this.colorChange(this.state.responsebody.status)}>
                <Alert.Heading>{this.statusUpdate(this.state.responsebody.status)}</Alert.Heading>
              </Alert>

            }
          </div>
          {this.state.responsebody &&
            <div className="gatsby-highlight" data-language="json">
              <CodeViewer
                aria-label={JSON.stringify(this.state.responsebody, undefined, 2)}
                noCopyButton
                maxHeight="400px"
                language="json"
                value={JSON.stringify(this.state.responsebody, undefined, 2)}
                showLineNumbers
                />
            </div>
          }
        </Form>
      </div>
    )
  }
}
