import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ChangeEventForm from '../components/send-change-event-form';
import V2EventForm from '../components/send-event-api-v2-form';

const SendEventForm = () => (
<Layout>
    <SEO title="Try Events API v2" />
    <header className="text-white bg-gray-dark relative">
        <div className="container mx-auto sm:flex sm:justify-center">
            <div className="py-4gut sm:py-0 sm:h-2lane sm:flex sm:flex-col sm:justify-center">
                <h1 className="font-extralight text-2xl md:text-3xl lg:text-4xl">
                Try out our <em className="not-italic text-yellow">Events v2 API</em>
                </h1>
            </div>
        </div>
    </header>
    <div className="bg-green mx-auto sm:flex sm:justify-center py-1gut">
        <h2 className="text-xl">Events v2</h2>
    </div>
    <section className="container mx-auto flex flex-col sm:justify-center">
        <div className="py-2gut px-4gut">
            <V2EventForm/>
        </div>
    </section>
    <div className="bg-green mx-auto sm:flex sm:justify-center py-1gut">
        <h2 className="text-xl">Change Events</h2>
    </div>
    <section className="container mx-auto flex flex-col sm:justify-center">
        <div className="py-2gut px-4gut justify-center">
            <ChangeEventForm/>
        </div>
    </section>
</Layout>
);

export default SendEventForm;